import { Button, Grid } from '@mui/material';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ScreenHeader } from '../components/screenHeader';
import {
    getConsumer,
    updateConsumer,
    changeStatusConsumer,
    deleteConsumer,
} from '../app/slices/consumersSlice';
import {
    fetchComplains,
    previousPage,
    nextPage,
    resetFilter,
    setFilter,
    checkItem,
    checkAllItems,
    uncheckItem,
    uncheckAllItems,
} from "../app/slices/complainsSlice";
import { withRouter } from '../wrappers/withRouter';
import { ConsumerComplainsList, ConsumerInfoForm, ConsumerInfoSection } from '../components/forms/consumer';
import { DeleteOutline } from '@mui/icons-material';
import {LAYOUT_CONFIG} from "../layoutConfig";
import {showModal} from "../app/slices/modalSlice";

class ConsumerEditScreen extends Component {
    constructor(props) {
        super(props);

        this.refresh();

        this.props.resetFilter();
        this.refreshComplains();
        
        this.handleRowCheck = this.handleRowCheck.bind(this);

        this.refresh = this.refresh.bind(this);
        this.refreshComplains = this.refreshComplains.bind(this);
        this.handleFieldSave = this.handleFieldSave.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    async refresh() {
        await this.props.getConsumer(this.props.params.id);
    }

    async refreshComplains() {
        await this.props.fetchComplains({ reporterId: this.props.params.id });
    }

    async handleFieldSave(field, value) {
        try {
            await this.props.updateConsumer({
                id: this.props.params.id,
                fieldName: field,
                value: value,
            });
        } catch (e) {
            console.log('e :>> ', e);
        }

        await this.refresh();
    }

    handlePreviousPage() {
        this.props.previousPage();
        this.refreshComplains();
    }

    handleNextPage() {
        this.props.nextPage();
        this.refreshComplains();
    }

    handleRequestSort(event, property) {
        const direction =
            this.props.filter.orderBy === property &&
            this.props.filter.orderDirection === "asc"
                ? "desc"
                : "asc";
        this.props.setFilter({ field: "orderBy", value: property });
        this.props.setFilter({ field: "orderDirection", value: direction });

        this.refreshComplains();
    }

    handleRowCheck(item, isChecked) {
        if (!isChecked) {
            this.props.checkItem(item);
        } else {
            this.props.uncheckItem(item);
        }
    }

    handleSelectAll() {
        if (this.props.selectedItems.length === this.props.complains.length) {
            this.props.uncheckAllItems();
        } else {
            this.props.checkAllItems();
        }
    }

    handleRowClick(id) {
        this.setState({
            open: this.state.open === id ? null : id,
        });
    }

    handleStatusChange() {
        const {id, isBlocked, fullName} = this.props.model;
        
        const content = {
            title: isBlocked ? 'Odblokowanie konta' : 'Zablokowanie konta',
            message: isBlocked ? 'Czy na pewno chcesz odblokować konto konsumenta' : 'Czy na pewno chcesz zablokować konto konsumenta',
            button: isBlocked ? 'Odblokuj' : 'Blokuj'
        }
        
        this.props.showModal({
            title: content.title,
            message: `${content.message} ${fullName}?`,
            button: content.button,
            mode: 'normal',
            cancelButton: true,
            fallback: () => {
                this.props.changeStatusConsumer(id).then(async () => {
                    await this.refresh();
                })
            }
        });
    }

    handleDelete() {
        this.props.showModal({
            title: 'Usunięcie konsumenta',
            message: `Czy na pewno chcesz usunąć konsumenta ${this.props.model.fullName}?`,
            button: 'Usuń',
            mode: 'normal',
            cancelButton: true,
            fallback: () => {
                this.props.deleteConsumer(this.props.model.id).then(async () => {
                    await this.refresh();
                })
            }
        });
    }

    render() {
        if (!this.props.model?.id)
            return;

        return (
            <div className="fk-screen">
                <ScreenHeader
                    withBackButton
                    withSeparator
                    title="consumerHeader"
                    titleReplacement={['#CONSUMER_NAME#', this.props.model.fullName]}
                    subtitle="consumerSubheader"
                    subtitleReplacement={['#CONSUMER_ID#', this.props.model.id]}
                    additionalButtons={
                        <div className="screen-header-buttons --flex-end">
                            {this.props.model.isBlocked
                                ? <Button className="green" onClick={this.handleStatusChange}>Odblokuj</Button>
                                : <Button className="green" onClick={this.handleStatusChange}>Zablokuj</Button>
                            }
                            <Button className="red" onClick={this.handleDelete}><DeleteOutline /> Usuń</Button>
                        </div>
                    }
                />
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <ConsumerInfoForm canEdit onUpdate={this.handleFieldSave} />
                    </Grid>
                    <Grid item md={6}>
                        {LAYOUT_CONFIG.USER_DETAILS.SHOW_POINTS && 
                            <ConsumerInfoSection />
                        }
                    </Grid>
                </Grid>
                <ConsumerComplainsList
                    consumerId={this.props.model.id}
                    selectedItems={this.props.selectedItems}
                    onRequestSort={this.handleRequestSort}
                    onRowCheck={this.handleRowCheck}
                    onSelectAll={this.handleSelectAll}
                    onPreviousPage={this.handlePreviousPage}
                    onNextPage={this.handleNextPage}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    model: state.consumers.consumer,
    complains: state.complains.data,
    filter: state.complains.filter,
    selectedItems: state.complains.selectedItems,
});

const actions = {
    fetchComplains,
    previousPage,
    nextPage,
    resetFilter,
    setFilter,
    checkItem,
    checkAllItems,
    uncheckItem,
    uncheckAllItems,
    getConsumer,
    updateConsumer,
    changeStatusConsumer,
    deleteConsumer,
    showModal
};

export default withRouter(connect(mapStateToProps, actions)(ConsumerEditScreen));